$c-blue:			#4d737f;
$c-blue-pale: #86aab5;
$c-black: 		#3e3a3a;
$c-white: 		#fff;

$padding-side: 16px;

$content-maw: 800px;
$content-maw-top: 540px;


html {
	font-family: 'Noto Sans JP', 'Arial', YuGothic, 'Yu Gothic', 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', 'メイリオ', Meiryo, 'ＭＳ ゴシック', sans-serif;
	font-size: 16px;
	font-weight: 400;
	letter-spacing: 0.1em;
	color: $c-black;
}

body {
	height: 100%;
	min-height: 100vh;
}

main {
	padding: 0 $padding-side;
	padding-top: 150px;
}

main > *,
.footer-inner {
	width: 100%;
	max-width: $content-maw;
	margin: 0 auto;
	counter-reset: counterH3;
}

.body-inner.-top {
	main > *,
	.footer-inner {
		max-width: $content-maw-top;
	}
}

.body-inner {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-height: 100vh;
}

h1 {
	width: 80%;
	margin: 0 auto;
}

h2 {
	margin-bottom: 48px;
	font-size: 24px;
	font-weight: 500;
	color: $c-blue;
	text-align: center;
}

h3 {
	margin-bottom: 24px;
	font-size: 18px;
	font-weight: 500;
	color: $c-blue;
}

h4 {
	margin-bottom: 24px;
	font-size: 16px;
	font-weight: 500;
	color: $c-blue;
}

p,
a,
li {
	margin-bottom: 2em;
	line-height: 2em;
	text-align: justify;

	&:last-child {
		margin-bottom: 0;
	}
}

.txt-right {
	margin-bottom: 2em;
	text-align: right;
}

a {
	font-size: 16px;
	color: $c-black;
	text-decoration: underline;

	&:hover {
		opacity: 0.8;
	}
}

.main-logo {
	width: 100%;
	max-width: 358px;
	margin: 0 auto;
	margin-bottom: 120px;
}

.sect-basic {
	margin-bottom: 4em;

	&.-top {
		margin-bottom: 80px;
	}

	&.-h4 {
		padding-top: 2em;
	}

	> h3 {
		position: relative;
		padding-bottom: 0.4em;
		padding-left: 1.4em;
		border-bottom: solid 1px $c-blue-pale;
		counter-increment: counterH3;

		&::before {
			position: absolute;
			top: 0;
			left: 0;
			content: counter(counterH3) '. ';
		}
	}

	li li {
		margin-bottom: 0.6em;
	}

	ol,
	ul {
		padding-left: 1.4em;
		margin-bottom: 2.0em;

		ol,
		ul {
			padding-top: 0.8em;
			margin-bottom: 1.4em;
		}
	}

	ol {
		list-style-type: decimal;

		ol {
			padding-left: 0;
			list-style: none;
			counter-reset: counterOl;

			> li {
				position: relative;
				padding-left: 1.6em;
				margin-left: -0.2em;
				counter-increment: counterOl;
			}

			> li::before {
				position: absolute;
				top: 0;
				left: 0;
				content: counter(counterOl) '）';
			}
		}
	}

	ul {
		list-style-type: disc;

		ul {
			list-style-type: circle;
		}
	}
}

.sect-underConstruction {
	padding-top: 30px;
	padding-bottom: 34px;
	margin-bottom: 90px;
	border-top: solid 1px $c-blue;
	border-bottom: solid 1px $c-blue;

	h2 {
		margin-bottom: 30px;
		color: $c-black;
	}

	p {
		text-align: center;
	}
}

.sect-contact {
	padding-top: 2em;

	h3 {
		margin-bottom: 40px;
		text-align: center;
	}
}

.contact-info {
	> div:first-child {
		padding-bottom: 0.8em;
		text-align: center;
	}

	span {
		display: inline-block;
	}

	table {
		width: 100%;
		border-collapse: none;
		border-top: solid 1px $c-blue-pale;
	}

	td {
		padding: 0.8em 0;
		vertical-align: middle;
		border-bottom: solid 1px $c-blue-pale;
	}

	td:first-child {
		padding-right: 0.8em;
		white-space: nowrap;
		border-right: solid 1px $c-blue-pale;
	}

	td:last-child {
		padding-left: 0.8em;
	}
}

.bottom-links {
	display: flex;
	justify-content: center;
	margin-bottom: 60px;
	gap: 24px;

	li {
		margin-bottom: 0;
	}
}

footer {
	width: 100%;
	padding: 30px $padding-side 50px;
	font-size: 12px;
	font-weight: 500;
	line-height: 1.8em;
	color: $c-white;
	background-color: $c-blue;

	.txt-em {
		font-size: 18px;
		line-height: 1.8em;
	}
}

.footer-inner {
	display: flex;
	justify-content: space-between;
	gap: 20px;
}

.footer-left {
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	div:first-child {
		margin-bottom: 10px;
	}
}

.footer-right {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

@media all and (max-width: 768px) {
	html {
		font-size: 14px;
	}

	a {
		font-size: 14px;
	}

	.bottom-links {
		align-items: center;
		flex-direction: column;
	}

	.footer-inner {
		flex-direction: column;
	}
}
