/*
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com
Twitter: @rich_clark
*/
html, body, div, object, iframe,
h1, h2, h3, h4, h5, h6, p, a, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
	padding: 0;
	margin: 0;
	vertical-align: baseline;
	background: transparent;
	border: 0;
	outline: 0;
	box-sizing: border-box;
}

body {
	/* プロジェクトによって変動 */
	width: 100%;
	-webkit-font-smoothing: antialiased;
	line-height: 1.6;
	color: #000;
	font-variant-numeric: tabular-nums;
	/* safari hover対策 */
}

main {
	box-sizing: border-box;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}

ul, ol {
	list-style: none;
}

blockquote, q {
	quotes: none;
}

blockquote::before, blockquote::after,
q::before, q::after {
	content: '';
}

a {
	padding: 0;
	margin: 0;
	color: inherit;
	text-decoration: none;
	vertical-align: baseline;
}

/* change colours to suit your needs */
ins {
	color: #000;
	text-decoration: none;
	background-color: #ff9;
}

/* change colours to suit your needs */
mark {
	font-style: italic;
	font-weight: bold;
	color: #000;
	background-color: #ff9;
}

del {
	text-decoration: line-through;
}

abbr[title], dfn[title] {
	cursor: help;
	border-bottom: 1px dotted;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

/* change border colour to suit your needs */
hr {
	display: block;
	height: 1px;
	padding: 0;
	margin: 1em 0;
	border: 0;
	border-top: 1px solid #cccccc;
}

input, select {
	vertical-align: middle;
}

img {
	line-height: 1.0;
	vertical-align: bottom;
}

button {
	padding: 0;
	line-height: 1.0;
	cursor: pointer;
	background-color: transparent;
	border: 0;
	outline: none;
	appearance: none;
}

input[type="submit"],
input[type="button"] {
	cursor: pointer;
	border: 0;
	border-radius: 0;
	-webkit-box-sizing: content-box;
	box-sizing: border-box;
	-webkit-appearance: button;
	appearance: button;
}

input[type="submit"]::-webkit-search-decoration,
input[type="button"]::-webkit-search-decoration {
	display: none;
}

input[type="submit"]:focus,
input[type="button"]:focus {
	outline-offset: -2px;
}
