@charset "UTF-8";
/*
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com
Twitter: @rich_clark
*/
html, body, div, object, iframe,
h1, h2, h3, h4, h5, h6, p, a, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  padding: 0;
  margin: 0;
  vertical-align: baseline;
  background: transparent;
  border: 0;
  outline: 0;
  box-sizing: border-box; }

body {
  /* プロジェクトによって変動 */
  width: 100%;
  -webkit-font-smoothing: antialiased;
  line-height: 1.6;
  color: #000;
  font-variant-numeric: tabular-nums;
  /* safari hover対策 */ }

main {
  box-sizing: border-box; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

ul, ol {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote::before, blockquote::after,
q::before, q::after {
  content: ''; }

a {
  padding: 0;
  margin: 0;
  color: inherit;
  text-decoration: none;
  vertical-align: baseline; }

/* change colours to suit your needs */
ins {
  color: #000;
  text-decoration: none;
  background-color: #ff9; }

/* change colours to suit your needs */
mark {
  font-style: italic;
  font-weight: bold;
  color: #000;
  background-color: #ff9; }

del {
  text-decoration: line-through; }

abbr[title], dfn[title] {
  cursor: help;
  border-bottom: 1px dotted; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  padding: 0;
  margin: 1em 0;
  border: 0;
  border-top: 1px solid #cccccc; }

input, select {
  vertical-align: middle; }

img {
  line-height: 1.0;
  vertical-align: bottom; }

button {
  padding: 0;
  line-height: 1.0;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  outline: none;
  appearance: none; }

input[type="submit"],
input[type="button"] {
  cursor: pointer;
  border: 0;
  border-radius: 0;
  -webkit-box-sizing: content-box;
  box-sizing: border-box;
  -webkit-appearance: button;
  appearance: button; }

input[type="submit"]::-webkit-search-decoration,
input[type="button"]::-webkit-search-decoration {
  display: none; }

input[type="submit"]:focus,
input[type="button"]:focus {
  outline-offset: -2px; }

html {
  font-family: 'Noto Sans JP', 'Arial', YuGothic, 'Yu Gothic', 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', 'メイリオ', Meiryo, 'ＭＳ ゴシック', sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.1em;
  color: #3e3a3a; }

body {
  height: 100%;
  min-height: 100vh; }

main {
  padding: 0 16px;
  padding-top: 150px; }

main > *,
.footer-inner {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  counter-reset: counterH3; }

.body-inner.-top main > *,
.body-inner.-top .footer-inner {
  max-width: 540px; }

.body-inner {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh; }

h1 {
  width: 80%;
  margin: 0 auto; }

h2 {
  margin-bottom: 48px;
  font-size: 24px;
  font-weight: 500;
  color: #4d737f;
  text-align: center; }

h3 {
  margin-bottom: 24px;
  font-size: 18px;
  font-weight: 500;
  color: #4d737f; }

h4 {
  margin-bottom: 24px;
  font-size: 16px;
  font-weight: 500;
  color: #4d737f; }

p,
a,
li {
  margin-bottom: 2em;
  line-height: 2em;
  text-align: justify; }
  p:last-child,
  a:last-child,
  li:last-child {
    margin-bottom: 0; }

.txt-right {
  margin-bottom: 2em;
  text-align: right; }

a {
  font-size: 16px;
  color: #3e3a3a;
  text-decoration: underline; }
  a:hover {
    opacity: 0.8; }

.main-logo {
  width: 100%;
  max-width: 358px;
  margin: 0 auto;
  margin-bottom: 120px; }

.sect-basic {
  margin-bottom: 4em; }
  .sect-basic.-top {
    margin-bottom: 80px; }
  .sect-basic.-h4 {
    padding-top: 2em; }
  .sect-basic > h3 {
    position: relative;
    padding-bottom: 0.4em;
    padding-left: 1.4em;
    border-bottom: solid 1px #86aab5;
    counter-increment: counterH3; }
    .sect-basic > h3::before {
      position: absolute;
      top: 0;
      left: 0;
      content: counter(counterH3) ". "; }
  .sect-basic li li {
    margin-bottom: 0.6em; }
  .sect-basic ol,
  .sect-basic ul {
    padding-left: 1.4em;
    margin-bottom: 2.0em; }
    .sect-basic ol ol,
    .sect-basic ol ul,
    .sect-basic ul ol,
    .sect-basic ul ul {
      padding-top: 0.8em;
      margin-bottom: 1.4em; }
  .sect-basic ol {
    list-style-type: decimal; }
    .sect-basic ol ol {
      padding-left: 0;
      list-style: none;
      counter-reset: counterOl; }
      .sect-basic ol ol > li {
        position: relative;
        padding-left: 1.6em;
        margin-left: -0.2em;
        counter-increment: counterOl; }
      .sect-basic ol ol > li::before {
        position: absolute;
        top: 0;
        left: 0;
        content: counter(counterOl) "）"; }
  .sect-basic ul {
    list-style-type: disc; }
    .sect-basic ul ul {
      list-style-type: circle; }

.sect-underConstruction {
  padding-top: 30px;
  padding-bottom: 34px;
  margin-bottom: 90px;
  border-top: solid 1px #4d737f;
  border-bottom: solid 1px #4d737f; }
  .sect-underConstruction h2 {
    margin-bottom: 30px;
    color: #3e3a3a; }
  .sect-underConstruction p {
    text-align: center; }

.sect-contact {
  padding-top: 2em; }
  .sect-contact h3 {
    margin-bottom: 40px;
    text-align: center; }

.contact-info > div:first-child {
  padding-bottom: 0.8em;
  text-align: center; }

.contact-info span {
  display: inline-block; }

.contact-info table {
  width: 100%;
  border-collapse: none;
  border-top: solid 1px #86aab5; }

.contact-info td {
  padding: 0.8em 0;
  vertical-align: middle;
  border-bottom: solid 1px #86aab5; }

.contact-info td:first-child {
  padding-right: 0.8em;
  white-space: nowrap;
  border-right: solid 1px #86aab5; }

.contact-info td:last-child {
  padding-left: 0.8em; }

.bottom-links {
  display: flex;
  justify-content: center;
  margin-bottom: 60px;
  gap: 24px; }
  .bottom-links li {
    margin-bottom: 0; }

footer {
  width: 100%;
  padding: 30px 16px 50px;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.8em;
  color: #fff;
  background-color: #4d737f; }
  footer .txt-em {
    font-size: 18px;
    line-height: 1.8em; }

.footer-inner {
  display: flex;
  justify-content: space-between;
  gap: 20px; }

.footer-left {
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  .footer-left div:first-child {
    margin-bottom: 10px; }

.footer-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between; }

@media all and (max-width: 768px) {
  html {
    font-size: 14px; }
  a {
    font-size: 14px; }
  .bottom-links {
    align-items: center;
    flex-direction: column; }
  .footer-inner {
    flex-direction: column; } }
